var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{staticClass:"map-path",class:[
    `map-path-${_vm.index + 1}`,
    { hide: _vm.status === 'blocked' || _vm.isLastCourse },
    { 'soft-appear': ['next-unlocked'].includes(_vm.status) },
    {
      'delayed-appear-market':
        ['current', 'next-unlocked'].includes(_vm.status) && _vm.nextUnlockedCourseIndex === 2,
    },
  ],style:(_vm.objectStyle),attrs:{"src":_vm.imgSrc,"alt":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }
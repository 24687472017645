<template>
  <div
    class="map_object"
    :class="{ locked: isLocked, highlighted: isHighlighted }"
    :style="objectStyle"
    @click="openCourse"
  >
      <div ref="inner-wrapper" class="inner-wrapper" :class="objectClasses">
      <div :class="status">
        <transition name="component-fade" mode="out-in">
          <div class="object-label" ref="mapObjectLabel" @click="openCourse">
            {{ planetStatus }}
          </div>
        </transition>
      </div>
    </div>
    <div class="mask" v-if="isHighlighted"></div>
  </div>
</template>

<script>
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  props: {
    nextUnlockedCourseIndex: {
      type: Number,
      required: true,
    },
    mapObjectConfig: {
      type: Object,
      required: true,
    },
    containerConfig: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      default: () => ({}),
    },
    isProgramBlocking: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    planetName: {
      type: String,
      required: true,
    },
    isLastCourse: {
      type: Boolean,
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.course.statistic.is_blocked) {
        return 'blocked';
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted && this.isProgramBlocking) {
        return 'next-unlocked';
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return 'current';
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return 'done';
      }
      return 'nostarted';
    },
    planetStatus() {
      if (this.course.statistic.is_blocked) {
        return this.$t('label.courseBlocked');
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return this.$t('label.courseInProcess');
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return this.$t('label.courseDone');
      }
      return this.$t('label.courseNotStarted');
    },
    objectStyle() {
      return {
        top: `${(this.mapObjectConfig.y / this.containerConfig.height) * 100}%`,
        left: `${(this.mapObjectConfig.x / this.containerConfig.width) * 100}%`,
        width: `${(this.mapObjectConfig.width / this.containerConfig.width) * 100}%`,
        height: `${(this.mapObjectConfig.height / this.containerConfig.height) * 100}%`,
      };
    },
    objectClasses() {
      return [
        `object-${this.index + 1}`,
        {
          colored:
            this.status === 'done'
            && (this.nextUnlockedCourseIndex - 1 !== this.index || this.isLastCourse),
          'colored-delayed':
            this.status === 'done'
            && this.nextUnlockedCourseIndex - 1 === this.index
            && !this.isLastCourse,
          'valley-appear':
            ['not-started', 'next-unlocked'].includes(this.status)
            && this.nextUnlockedCourseIndex === 2,
          'visible-valley-label':
            ['current', 'done'].includes(this.status) || !this.isProgramBlocking,
        },
      ];
    },
    isLocked() {
      return this.course.statistic?.is_blocked;
    },
  },
  methods: {
    openCourse() {
      if (this.isLocked) {
        return;
      }
      this.$emit('click');
      const { programId } = this.$route.params;
      this.$router.push(`/course-user/${programId}/${this.course._id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
$transition-time: 1s;

.object- {
  @for $i from 1 through 8 {
    &#{$i} {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('~@/assets/images/png/map/default/#{$i}.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('~@/assets/images/png/map/active/#{$i}.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        z-index: 2;
      }

      &.colored:not(.colored-delayed) {
        &:after {
          opacity: 1;
        }
      }
      &.colored-delayed {
        &:after {
          animation: opacity2 1s ease-out forwards 500ms;

          @keyframes opacity2 {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &3 {
    & > .blocked {
      opacity: 0;
    }
    &:before {
      opacity: 0.8;
    }
    &.valley-appear {
      &:before {
        animation: fade 1s ease-out forwards 6000ms;
        @keyframes fade {
          0% {
            opacity: 0.8;
          }
          100% {
            opacity: 0;
          }
        }
      }
      &:after {
        background-image: url('~@/assets/images/png/map/default/3-labeled.png');
        animation: opacity2 1s ease-out forwards 6000ms;
        @keyframes opacity2 {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
  &3.visible-valley-label {
    &:before {
      background-image: url('~@/assets/images/png/map/default/3-labeled.png');
      opacity: 1;
    }
  }
}
</style>

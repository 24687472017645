var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{staticClass:"map-cross",class:[
    `map-cross-${_vm.index + 1}`,
    { hide: !['current', 'next-unlocked'].includes(_vm.status) },
    { 'soft-appear': ['next-unlocked'].includes(_vm.status) && _vm.index !== 0 },
    {
      'delayed-appear-market':
        ['current', 'next-unlocked'].includes(_vm.status) && _vm.nextUnlockedCourseIndex === 2,
    },
    { highlighted: _vm.isHighlighted },
  ],style:(_vm.objectStyle),attrs:{"src":_vm.imgSrc,"alt":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="course-card-student" :class="{ locked: isLocked }" @click="openCourse">
    <div class="course-card-student__top">
      <div class="course-card-student__planet">
        <img :src="imageSrc" alt="planet" />
      </div>
      <div class="course-card-student__info info">
        <div class="info__title">
          <div>{{ narativeName }}</div>
          <div v-if="isLocked" class="info__lock-icon"><LockIcon /></div>
        </div>
        <div class="info__status">
          <ProgressStatus class="status" iconPosition="left" :status="status" />
        </div>
      </div>
    </div>
    <div class="course-card-student__bottom">
      <div v-if="false" class="course-card-student__date">12 - 25 сентября 2022</div>
      <div class="course-card-student__description">
        {{ courseDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import ProgressStatus from '@/components/ProgressStatus.vue';
import {
  blocked, doing, done, notStarted, rejected,
} from '@/utils/constants';
import LockIcon from '@/components/Icons/LockIcon.vue';
import deviceMixin from '@/mixins/device.mixin';
import { ActivityStatuses } from '@/utils/statusConstants';
import { planets, narrativeDescription } from '../../../config/app_config.json';

export default {
  name: 'CourseCardStudent',
  props: {
    program: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    LockIcon,
    ProgressStatus,
  },
  mixins: [deviceMixin],
  data: () => ({
    planets,
  }),
  computed: {
    percent() {
      return Math.round((this.course.statistic.score / this.course.max_score) * 100) || 0;
    },
    isLocked() {
      return this.course.statistic.is_blocked;
    },
    status() {
      if (this.course.statistic.is_blocked) {
        return this.$device.isMobile ? { ...blocked, label: 'label.blocked2' } : blocked;
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return this.$device.isMobile ? { ...done, label: 'label.passed2' } : done;
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return this.$device.isMobile ? { ...doing, label: 'label.inProgress2' } : doing;
      }
      if (this.course.statistic.status === ActivityStatuses.rejected) {
        return this.$device.isMobile ? { ...rejected, label: 'label.rejected2' } : rejected;
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted) {
        return this.$device.isMobile ? { ...notStarted, label: 'label.notStarted2' } : notStarted;
      }
      return this.$device.isMobile ? { ...notStarted, label: 'label.notStarted2' } : notStarted;
    },
    narativeName() {
      if (!this.program.is_blocking) {
        return `${this.index + 1}. ${this.$i18n.t(`planets.${this.planets[this.index + 1]}`)}`;
      }
      if (this.isLocked && this.index === 2) {
        return `${this.index + 1}. ${this.$i18n.t('planets.gott')}`;
      }
      return `${this.index + 1}. ${this.$i18n.t(`planets.${this.planets[this.index + 1]}`)}`;
    },
    imageSrc() {
      if (this.isLocked && this.index === 2) {
        return require('@/assets/images/png/map/tablet/default/market.png');
      }
      const imageStatus = this.status?.value === 'done' ? 'active' : 'default';
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/png/map/tablet/${imageStatus}/${this.index + 1}.png`);
    },
    courseDescription() {
      return this.course.description || narrativeDescription[this.index];
    },
  },
  methods: {
    openCourse() {
      if (this.isLocked) {
        return;
      }
      const { programId } = this.$route.params;
      this.$router.push(`/course-user/${programId}/${this.course._id}`);
    },
  },
};
</script>

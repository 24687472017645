<template>
  <div
    class="map_object market"
    :class="{
      disapear: courseTreeIsNotStarted && isProgramBlocking,
      hide:
        !isProgramBlocking ||
        nextIndex > 2 ||
        lastCourseIndexInProgress > 1 ||
        (lastCourseIndexInProgress === -1 && nextIndex === -1),
    }"
  >
    <div class="inner-wrapper">
      <div :class="status">
        <transition name="component-fade" mode="out-in">
          <div class="object-label" ref="mapObjectLabel">
            {{ labelStatus }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import findLastIndex from 'lodash/findLastIndex';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  props: {
    nextIndex: {
      type: Number,
      required: true,
    },
    courses: {
      type: Array,
      default: () => [],
    },
    isProgramBlocking: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    status() {
      return 'next-unlocked' || 'blocked' || 'not-started';
    },
    labelStatus() {
      return 'Курс заблокирован';
    },
    courseTreeIsNotStarted() {
      return (
        this.courses[2]?.statistic?.status === ActivityStatuses.notStarted
        && !this.courses[2]?.statistic?.is_blocked
      );
    },
    lastCourseIndexInProgress() {
      return findLastIndex(
        this.courses,
        (i) => i.statistic.status === ActivityStatuses.doing
          && !i.statistic.is_blocked
          && this.isProgramBlocking,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.market {
  top: 40.75%;
  left: 13.93%;
  width: 19.79%;
  height: 19.27%;
  background-image: url('~@/assets/images/png/map/default/market.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &.disapear {
    opacity: 1;
    animation: opacityOn 1.5s ease-out forwards;
    animation-delay: 2s;
    animation-iteration-count: 1;

    @keyframes opacityOn {
      0% {
        opacity: 1;
      }
      33% {
        opacity: 0;
      }
      66% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  &.hide:not(.disapear) {
    visibility: hidden;
    opacity: 0;
  }
  &.map_object:hover {
    cursor: default;
    transform: none;
  }
}
</style>

<template>
  <div class="onboarding-wrapper">
    <div class="onboarding--preinc">
      <div class="onboarding--preinc__inner-container">
        <div class="onboarding--preinc__label">
          <p>
            {{ label }}
            <span @click="$emit('tutorial', tutorialStep)" v-if="actionText">
              {{ actionText }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingCardPreinc',
  props: {
    label: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: '',
    },
    tutorialStep: {
      type: String,
      default: '',
    },
  },
};
</script>

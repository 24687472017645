var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_object market",class:{
    disapear: _vm.courseTreeIsNotStarted && _vm.isProgramBlocking,
    hide:
      !_vm.isProgramBlocking ||
      _vm.nextIndex > 2 ||
      _vm.lastCourseIndexInProgress > 1 ||
      (_vm.lastCourseIndexInProgress === -1 && _vm.nextIndex === -1),
  }},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{class:_vm.status},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('div',{ref:"mapObjectLabel",staticClass:"object-label"},[_vm._v(" "+_vm._s(_vm.labelStatus)+" ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
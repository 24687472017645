<template>
  <img
    :src="imgSrc"
    :style="objectStyle"
    class="map-path"
    :class="[
      `map-path-${index + 1}`,
      { hide: status === 'blocked' || isLastCourse },
      { 'soft-appear': ['next-unlocked'].includes(status) },
      {
        'delayed-appear-market':
          ['current', 'next-unlocked'].includes(status) && nextUnlockedCourseIndex === 2,
      },
    ]"
    alt
  />
</template>

<script>
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'MapPath',
  props: {
    nextUnlockedCourseIndex: {
      type: Number,
      required: true,
    },
    mapObjectConfig: {
      type: Object,
      required: true,
    },
    containerConfig: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      default: () => ({}),
    },
    isProgramBlocking: {
      type: Boolean,
      required: true,
    },
    isLastCourse: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    status() {
      if (this.course.statistic?.is_blocked) {
        return 'blocked';
      }
      if (this.course.statistic?.status === ActivityStatuses.notStarted && this.isProgramBlocking) {
        return 'next-unlocked';
      }
      if (this.course.statistic?.status === ActivityStatuses.doing) {
        return 'current';
      }
      if (this.course.statistic?.status === ActivityStatuses.done) {
        return 'done';
      }
      return 'nostarted';
    },
    objectStyle() {
      // const imageStatus = this.status === 'done' ? 'active' : 'default';
      return {
        top: `${(this.mapObjectConfig.y / this.containerConfig.height) * 100}%`,
        left: `${(this.mapObjectConfig.x / this.containerConfig.width) * 100}%`,
        width: `${(this.mapObjectConfig.width / this.containerConfig.width) * 100}%`,
        height: `${(this.mapObjectConfig.height / this.containerConfig.height) * 100}%`,
      };
    },
    isLocked() {
      return this.course.statistic?.is_blocked;
    },
    imgSrc() {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/png/map/path/${this.index + 1}.svg`);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.map-path {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  &:not(.soft-appear) {
    opacity: 1;
  }
  &.soft-appear:not(.delayed-appear-market) {
    opacity: 0;
    animation: opacityOn 500ms ease-out forwards;
    animation-delay: 1.5s;
    animation-iteration-count: 1;

    @keyframes opacityOn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.delayed-appear-market {
    opacity: 0;
    animation: opacityOn 500ms ease-out forwards;
    animation-delay: 4s;
    animation-iteration-count: 1;

    @keyframes opacityOn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>

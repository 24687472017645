<template>
  <img
    :src="imgSrc"
    :style="objectStyle"
    class="map-cross"
    :class="[
      `map-cross-${index + 1}`,
      { hide: !['current', 'next-unlocked'].includes(status) },
      { 'soft-appear': ['next-unlocked'].includes(status) && index !== 0 },
      {
        'delayed-appear-market':
          ['current', 'next-unlocked'].includes(status) && nextUnlockedCourseIndex === 2,
      },
      { highlighted: isHighlighted },
    ]"
    alt
  />
</template>

<script>
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'MapCross',
  props: {
    mapObjectConfig: {
      type: Object,
      required: true,
    },
    containerConfig: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      default: () => ({}),
    },
    isProgramBlocking: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    nextUnlockedCourseIndex: {
      type: Number,
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.course.statistic?.is_blocked) {
        return 'blocked';
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted && this.isProgramBlocking) {
        return 'next-unlocked';
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return 'current';
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return 'done';
      }
      return 'nostarted';
    },
    objectStyle() {
      // const imageStatus = this.status === 'done' ? 'active' : 'default';
      return {
        top: `${(this.mapObjectConfig.y / this.containerConfig.height) * 100}%`,
        left: `${(this.mapObjectConfig.x / this.containerConfig.width) * 100}%`,
        width: `${(32 / this.containerConfig.width) * 100}%`,
        height: `${(32 / this.containerConfig.height) * 100}%`,
      };
    },
    isLocked() {
      return this.course.statistic?.is_blocked;
    },
    imgSrc() {
      // eslint-disable-next-line import/no-dynamic-require
      return require('@/assets/images/png/map/red-cross.svg');
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.map-cross {
  position: absolute;
  pointer-events: none;
  width: 2rem;
  height: 2rem;
  opacity: 1;
  z-index: -1;
  &.hide {
    visibility: hidden;
  }

  &.highlighted {
    z-index: 4;
  }

  &.soft-appear:not(.delayed-appear-market) {
    opacity: 0;
    animation: opacityOn 500ms ease-out forwards;
    animation-delay: 2.25s;
    animation-iteration-count: 1;

    @keyframes opacityOn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.delayed-appear-market {
    opacity: 0;
    animation: opacityOn 500ms ease-out forwards;
    animation-delay: 5s;
    animation-iteration-count: 1;

    @keyframes opacityOn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
